<template lang="">
  <div>
    <div class="flex flex-col gap-2">
      <div class="flex gap-2 items-center justify-between">
        <div>Move Map</div>
        <div class="flex gap-1 items-center justify-between">
          <img
            src="@/assets/img/guideline/left-click.png"
            class="w-5 h-5"
            v-tooltip.bottom="
              `Press and Hold Mouse Left Key.Try to Move the mouse.`
            "
          />
        </div>
      </div>
      <div class="flex gap-2 items-center justify-between">
        <div>Zoom In Out</div>
        <div class="flex gap-1 items-center justify-between">
          <img
            src="@/assets/img/guideline/mouse-scroll.png"
            class="w-5 h-5"
            v-tooltip.bottom="`Scroll the mouse`"
          />
        </div>
      </div>
      <div class="flex gap-2 items-center justify-between">
        <div>Rotate Map</div>
        <div class="flex gap-1 items-center justify-between">
          <img
            v-if="isMac"
            src="@/assets/img/guideline/cmd.png"
            class="w-5 h-5"
            v-tooltip.bottom="`Press and Hold Command Key`"
          />
          <img
            v-if="!isMac"
            src="@/assets/img/guideline/alt.png"
            class="w-5 h-5"
            v-tooltip.bottom="`Press and Hold Alt Key`"
          />
          <img src="@/assets/img/guideline/plus.png" class="w-3 h-3" />
          <img
            src="@/assets/img/guideline/left-click.png"
            class="w-5 h-5"
            v-tooltip.bottom="
              `Press and Hold Mouse Left Key.Try to Move the mouse.`
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isMac() {
      return navigator.platform.toUpperCase().indexOf('MAC') >= 0
    },
  },
}
</script>
<style lang=""></style>
