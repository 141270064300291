<template>
  <div class="flex flex-col">
    <div class="flex">
      <div
        class="w-5 h-5 isSelected"
        style="backgroundColor:rgb(163 230 53)"
        v-tooltip.top="`10%`"
      ></div>
      <div
        class="w-5 h-5 isSelected"
        style="backgroundColor:rgb(132 204 22)"
        v-tooltip.top="`20%`"
      ></div>
      <div
        class="w-5 h-5 isSelected"
        style="backgroundColor:rgb(1 163 13)"
        v-tooltip.top="`30%`"
      ></div>

      <div
        class="w-5 h-5 isSelected"
        style="backgroundColor:rgb(250 204 21)"
        v-tooltip.top="`40%`"
      ></div>
      <div
        class="w-5 h-5 isSelected"
        style="backgroundColor:rgb(234 179 8)"
        v-tooltip.top="`50%`"
      ></div>
      <div
        class="w-5 h-5 isSelected"
        style="backgroundColor:rgb(202 138 4)"
        v-tooltip.top="`60%`"
      ></div>

      <div
        class="w-5 h-5 isSelected"
        style="backgroundColor:rgb(248 113 113)"
        v-tooltip.top="`70%`"
      ></div>
      <div
        class="w-5 h-5 isSelected"
        style="backgroundColor:rgb(220 38 38)"
        v-tooltip.top="`80%`"
      ></div>
      <div
        class="w-5 h-5 isSelected"
        style="backgroundColor:rgb(185 28 28)"
        v-tooltip.top="`90%`"
      ></div>
    </div>
    <div class="flex items-center justify-between text-sm">
      <p>Low</p>
      <p>Medium</p>
      <p>High</p>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.isSelected {
  @apply border-2 border-transparent hover:border-gray-600;
}
</style>
